const ENUM = {
  url: {
    site: "https://pegadababy.mercadoshops.com.br/",
    shopee: "https://shopee.com.br/pegada_baby",
    mercadoLivre: "https://pegadababy.mercadoshops.com.br/",
    magazineLuiza: "https://www.magazineluiza.com.br/lojista/pegadababyme/",
    lojasAmericanas: "https://www.americanas.com.br/lojista/pegada-baby",
    shopTime: "https://www.shoptime.com.br/lojista/pegada-baby",
    submarino: "https://www.submarino.com.br/lojista/pegada-baby",
    whatsApp: "https://api.whatsapp.com/send?phone=5531971720713",
    instagram: "https://www.instagram.com/pegada_baby/",
  }
};

export default ENUM;